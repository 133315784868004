import { useRef } from "react";
import Card from "../ui/Card";
import classes from './NewMeetupForm.module.css';

function NewMeetupForm(props) {
    const titleImputRef = useRef();
    const imageRef = useRef();
    const addressRef = useRef();
    const descriptionRef = useRef();

    function submitHandler(event){
        event.preventDefault();
        const enteredTitle = titleImputRef.current.value;
        const enteredImage = imageRef.current.value;
        const enteredAddress = addressRef.current.value;
        const enterredDescription = descriptionRef.current.value;
        const meetup = {
            title: enteredTitle,
            image:enteredImage,
            address:enteredAddress,
            description: enterredDescription
        };
        console.log(meetup);
        props.onAddMeetup(meetup);
    }
    return (
        <Card>
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.card}>
                    <div className={classes.control}>
                        <label htmlFor='title'>meetupTitle</label>
                        <input type="Text"  id="title" ref={titleImputRef}></input>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='image'>ImageUrl</label>
                        <input type="Text"  id="image" ref={imageRef} ></input>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='adress'>adress</label>
                        <input type="Text" id="adress" ref={addressRef}></input>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='description'>adress</label>
                        <textarea rows='5' id="description" ref={descriptionRef}></textarea>
                    </div>
                    <div className={classes.actions}>
                        <button id="submit">Add meetup</button>
                    </div>
                </div>
            </form>
        </Card>);
}

export default NewMeetupForm;