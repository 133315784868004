import {Link} from 'react-router-dom'
import classes from './MainNavigtion.module.css' 

function MainNavigation(){
return <header className={classes.header}>
    <div className={classes.logo}>react meetups</div>
    <nav>
        <ul>
            <li>
                <Link to = '/' >all metups</Link> 
            </li>

            <li>
                <Link to = '/new-meetup' >new metups</Link> 
            </li>

            <li>
                <Link to = '/favorites' >favorites</Link> 
            </li>
        </ul>
    </nav>
</header>
}
export default MainNavigation;