// import Todo from './components/Todo'
import {Route, Routes} from 'react-router-dom'
import AllMeetupsPage from './pages/AllMeetups';
import FavoritesMeetupPage from './pages/FavoritesMeetup';
import NewMeetupPage from './pages/NewMeetup';
import MainNavigation from './components/layout/MainNavigation';
function App() {
  return (
    <div>
      <MainNavigation/>
      <Routes>
        <Route path='/' element={<AllMeetupsPage/>}>
        </Route>
        <Route path='/new-meetup' element={<NewMeetupPage/>}>
        </Route>
        <Route path='/favorites' element={<FavoritesMeetupPage/>}>
            {/* <FavoritesMeetupPage/> */}
        </Route>
      </Routes>
      {/* <h1>To Do</h1>
    
      <Todo text='do1'/>
      <Todo text = 'do2'/>
      <Todo text = "do3"/> */}
      
    </div>);
}

export default App;
