import NewMeetupForm from "../components/meetups/NewMeetupForm";

function NewMeetupPage(){
    function addMeetup(meetupData){
       fetch('url'); 
    }
    return <section><h1>Add new meetup</h1>
    <NewMeetupForm onAddMeetup={addMeetup}/>
    </section>;
}

export default NewMeetupPage;