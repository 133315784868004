import Card from "../ui/Card";
import classes from './MeetupItem.module.css'

function MeetupItem(props){
    return (<li>
        <Card>
                <div className={classes.image}>
                    <img src={props.meetup.image} alt={props.meetup.title}/>
                </div> 
                <div className={classes.content}>
                    <h3>{props.meetup.title}</h3>
                    <address> {props.meetup.address} </address>
                    <p>{props.meetup.description}</p>
                </div>
                <div className={classes.actions}>
                    <button >add to favorites</button>
                </div>
                </Card>
            </li>);
}

export default MeetupItem;